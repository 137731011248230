import { getFormattedAmount } from "./Helpers";
import NetWorthTrend from "./NetWorthTrend";
import { useEffect, useContext } from "react";
import PearCard from "./PearCard";
import PieChart from "./PieChart";
import { useSelector, useDispatch } from "react-redux";
import { fetchAccounts } from "../pear-state/account_slice";
import EmptyState from "./EmptyState";
import {
  fetchNetworthDistribution,
  fetchAccountTypeDistribution,
  fetchSectorDistribution,
  fetchExchangeRate,
  fetchOverallPicture,
} from "../pear-state/user_slice";
import Skeleton from "./interface/Skeleton";
import { AppDispatch, RootState, StoreStatus } from "pear-state/store";
import Heatmap from "./Heatmap";
import { fetchStocks } from "pear-state/stock_slice";
import CardWithChange from "./CardWithChange";
import MaskableText from "./interface/MaskableText";
import { ConfigurationContext } from "pear-state/configuration_context";

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const networthDistribution = useSelector((state: RootState) => state.users.networthDistribution);
  const accountTypeDistribution = useSelector((state: RootState) => state.users.accountTypeDistribution);
  const sectorDistribution = useSelector((state: RootState) => state.users.sectorDistribution);
  const overallPicture = useSelector((state: RootState) => state.users.overallPicture);
  const equitiesData = useSelector((state: RootState) => state.stocks.stocks);

  const overallPictureStatus = useSelector((state: RootState) => state.users.overallPictureStatus);
  const networthDistributionStatus = useSelector((state: RootState) => state.users.networthDistributionStatus);
  const accountTypeDistributionStatus = useSelector((state: RootState) => state.users.accountTypeDistributionStatus);
  const sectorDistributionStatus = useSelector((state: RootState) => state.users.sectorDistributionStatus);
  const exchangeRateStatus = useSelector((state: RootState) => state.users.exchangeRateStatus);
  const accountsStatus = useSelector((state: RootState) => state.accounts.status);
  const equitiesStatus = useSelector((state: RootState) => state.stocks.status);

  const { balanceLookbackInDays } = useContext(ConfigurationContext);

  useEffect(() => {
    if (accountsStatus === StoreStatus.IDLE) {
      dispatch(fetchAccounts());
    }

    if (accountTypeDistributionStatus === StoreStatus.IDLE) {
      dispatch(fetchAccountTypeDistribution());
    }

    if (sectorDistributionStatus === StoreStatus.IDLE) {
      dispatch(fetchSectorDistribution());
    }

    if (exchangeRateStatus === StoreStatus.IDLE) {
      dispatch(fetchExchangeRate());
    }

    if (overallPictureStatus === StoreStatus.IDLE) {
      dispatch(fetchOverallPicture());
    }

    if (equitiesStatus === StoreStatus.IDLE) {
      dispatch(fetchStocks());
    }
  }, [dispatch]);

  useEffect(() => {
    if (networthDistributionStatus !== StoreStatus.LOADING) {
      dispatch(fetchNetworthDistribution(balanceLookbackInDays));
    }
  }, [dispatch, balanceLookbackInDays]);

  function renderDashboard() {
    if (overallPictureStatus == StoreStatus.LOADING) {
      return <Skeleton />;
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
        <div className="grid grid-cols-2 md:grid-cols-5 md:col-span-3 col-span-1 gap-4 w-full">
          <PearCard title="Net worth">
            <CardWithChange
              value={overallPicture?.total_holdings}
              change={overallPicture?.net_worth_change || 0}
              displayCurrency="CAD"
            />
          </PearCard>
          <PearCard title="Equity value">
            <MaskableText value={getFormattedAmount(overallPicture?.total_equity_value, "CAD")} />
          </PearCard>
          <PearCard title="Available cash">
            <MaskableText value={getFormattedAmount(overallPicture?.total_available_cash, "CAD")} />
          </PearCard>
          <PearCard title="Dividend earnings">
            <MaskableText value={getFormattedAmount(overallPicture?.total_dividends, "CAD")} />
          </PearCard>
          <PearCard title="Commission paid">
            <MaskableText value={getFormattedAmount(overallPicture?.total_commission, "CAD")} />
          </PearCard>
        </div>

        <PearCard
          classNames={["md:col-span-3", "col-span-1"]}
          title="Net worth trend"
          loading={networthDistributionStatus == "loading"}
        >
          <NetWorthTrend data={networthDistribution} />
        </PearCard>
        <PearCard title="Account distribution" loading={accountTypeDistributionStatus == "loading"}>
          <PieChart data={accountTypeDistribution} />
        </PearCard>
        <PearCard title="Currency distribution">
          <PieChart
            data={[
              { name: "CAD", balance: overallPicture?.cad_holdings || 0 },
              { name: "USD", balance: overallPicture?.usd_holdings || 0 },
            ]}
          />
        </PearCard>
        <PearCard title="Sector distribution" loading={sectorDistributionStatus == "loading"}>
          <PieChart data={sectorDistribution} />
        </PearCard>

        <PearCard
          classNames={["md:col-span-3", "col-span-1"]}
          title="Your holdings today"
          loading={equitiesStatus == "loading"}
        >
          <Heatmap data={equitiesData} />
        </PearCard>
      </div>
    );
  }
  return <>{accounts.length === 0 && accountsStatus === "succeeded" ? <EmptyState /> : renderDashboard()}</>;
};

export default Dashboard;
