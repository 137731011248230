import { Listbox, Transition } from "@headlessui/react";
import { useController } from "react-hook-form";
import classNames from "classnames";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

interface SelectFieldProps {
  items: { uuid: string; name: string; subtext?: string }[];
  displayName: string;
  formName: string;
  control: any;
  rules?: any;
  widthClass?: string;
}

const SelectField = ({ items, displayName, formName, control, rules, widthClass }: SelectFieldProps) => {
  const {
    field: { value, onChange },
  } = useController({ name: formName, control, rules });

  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      const selectedItem = items.find((item) => item.uuid === value);
      setSearchTerm(selectedItem?.name || "");
    }
  }, [value, items]);

  const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  return (
    <div ref={containerRef} className="relative">
      <Listbox
        value={value}
        onChange={(val) => {
          onChange(val);
          const selectedItem = items.find((item) => item.uuid === val);
          setSearchTerm(selectedItem?.name || "");
          setIsOpen(false); // Close dropdown after selection
        }}
      >
        <div className="relative mt-1">
          {/* Input field behaves as Listbox Button */}
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsOpen(true)}
            className={classNames(
              "w-full border border-gray-300 rounded-md px-3 py-2 text-sm shadow-sm focus:ring-2 focus:ring-indigo-500 focus:outline-none",
              widthClass || "w-full"
            )}
          />
          <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>

          <Listbox.Label className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-500">
            {displayName}
          </Listbox.Label>

          <Transition
            show={isOpen}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <Listbox.Option
                    key={item.uuid}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.uuid}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                          {item.name}
                        </span>
                        {item.subtext && (
                          <div className={classNames("text-xs", active ? "text-slate-100" : "text-slate-400")}>
                            {item.subtext}
                          </div>
                        )}
                        {selected && (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <div className="px-3 py-2 text-gray-500 text-sm">No results found</div>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SelectField;
